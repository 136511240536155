import * as React from 'react';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { Box, Form, FormField, Button, Spinner } from 'grommet';
import { FormNext } from 'grommet-icons';
import { navigate } from 'gatsby';
import { PhoneInput } from 'app/design/PhoneInput';
import api from '../../services/api';
import app from '../../firebase';

function setupRecaptacha(callback: (token: any) => Promise<void>) {
  const auth = getAuth(app);
  window.recaptchaVerifier = new RecaptchaVerifier(
    'continue',
    {
      size: 'invisible',
      callback
    },
    auth
  );
  window.recaptchaVerifier.render().then((widgetId) => {
    window.recaptchaWidgetId = widgetId;
  });
}

export default function Home(props) {
  const [loading, setLoading] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    setupRecaptacha(async (token) => {
      setToken(token);
    });
  }, []);

  React.useEffect(() => {
    async function run(token) {
      setLoading(true);
      const resp = await api.get(`/auth/providers/gpa/send/${phone}/${token}`);
      if (resp.status === 200) {
        navigate(`/auth/verify?phone=${phone}`);
      }
      setToken('');
      setLoading(false);
    }
    if (phone && token && !loading) run(token);
  }, [loading, phone, token]);

  return (
    <div className="flex h-screen flex-col bg-gray-200">
      <div className="mx-auto w-full py-8 px-2 md:w-1/2 lg:w-4/12 lg:p-8">
        <div className="rounded-t-lg bg-white p-8">
          <Box
            align="start"
            justify="start"
            pad={{ bottom: 'small', left: 'small' }}
            direction="column">
            <h1 className="text-lg font-bold">Login with your Phone Number</h1>
          </Box>
          <Form>
            <FormField label="Phone Number">
              <PhoneInput
                placeholder="Enter your phone number"
                name="phone"
                value={phone}
                onChange={setPhone}
              />
            </FormField>
            <Box align="start" justify="center">
              <Button
                primary
                id="continue"
                label="Continue"
                disabled={loading === true || !phone}
                icon={loading ? <Spinner /> : <FormNext />}
              />
            </Box>
          </Form>
        </div>
      </div>
    </div>
  );
}
