import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useAuth } from 'context/auth';
import { OrganizationSignup } from '../../components';
import { createOrganization } from '../../services/organization';

function Organization() {
  const { refresh } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onCreate = async (data) => {
    setLoading(true);
    setError('');
    try {
      await createOrganization(data);
      await refresh();
      navigate('/');
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
  };
  return <OrganizationSignup onCreate={onCreate} busy={loading} error={error} />;
}

export default Organization;
