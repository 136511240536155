import { useAuth } from 'context/auth';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { createMedia } from 'services/media';
import { EditBusiness } from 'app/features/business';
import { useOrganization } from '../../context/organization';
import { updateOrganization } from '../../services/organization';

export default function Edit() {
  const {
    refresh,
    state: { session }
  } = useAuth();
  const { organization, loading } = useOrganization();
  const [busy, setBusy] = useState(loading);
  const [error, setError] = useState('');
  const [upload, setUpload] = useState(null);

  const onCreate = async (data) => {
    setBusy(true);
    setError('');
    delete data.banner;
    delete data.banner_media_id;
    const mergedData = { ...data };
    if (upload) {
      mergedData.banner_media_id = upload.media.id;
    }
    try {
      await toast.promise(updateOrganization(organization.id, mergedData), {
        loading: 'working...',
        success: 'done',
        error: (e) => e.message
      });
      await refresh();
    } catch (error) {
      setError(error.message);
    }
    setBusy(false);
  };

  useEffect(() => {
    setBusy(loading);
  }, [loading]);

  const onUpload = (files) => {
    if (busy) return;
    setBusy(true);
    async function run() {
      try {
        const upl = await toast.promise(
          createMedia('organization_banner', files[0], (received, total) => {
            // console.log({ received, total });
          }),
          {
            loading: 'uploading banner',
            success: 'done',
            error: (e) => e.message
          }
        );
        setUpload(upl);
      } catch (error) {}
      setBusy(false);
    }
    run();
  };
  if (!organization)
    return (
      <div className="p4 mx-auto my-auto">
        <svg className="... mr-3 h-5 w-5 animate-spin" viewBox="0 0 24 24" />
      </div>
    );
  return (
    <EditBusiness
      busy={busy}
      onUpload={onUpload}
      onSubmitDetailForm={onCreate}
      defaultValues={{
        ...organization,
        banner_file: upload ? { files: [upload.upload.url] } : { files: [organization?.banner] }
      }}
    />
  );
}
