import {
  Anchor,
  Box,
  Text,
  Image,
  Menu,
  Button,
  Nav,
  Sidebar,
  Collapsible,
  Layer,
  ResponsiveContext
} from 'grommet';
import * as React from 'react';
import { Header } from 'grommet-controls';
import {
  Analytics,
  Configure,
  Contact,
  Help,
  Logout,
  Projects,
  StatusInfoSmall
} from 'grommet-icons';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Squeeze as Hamburger } from 'hamburger-react';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import GitInfo from 'react-git-info/macro';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import { ISession } from 'business/models/session';
import { useAuth } from 'context/auth';
import { Navbar } from 'app/design/Navbar';
import { LogoIcon } from 'app/design/LogoIcon';
import logo from './Changemida-logomark+logotype-4.png';

const gitInfo = GitInfo();

function errorResponseHandler(error) {
  // check for errorHandle config
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    //  toast.error(error.response.data.message);
  }
  return error;
}

axios.interceptors.response.use((response) => response, errorResponseHandler);

// https://www.propelauth.com/post/setting-up-a-responsive-b2b-template-with-grommet-propelauth-and-next-js

function SidebarHeader({
  isOpen,
  session,
  onToggle
}: {
  isOpen: boolean;
  session: ISession;
  onToggle: any;
}) {
  return (
    <Box direction="row">
      {session?.data && (
        <Hamburger hideOutline rounded size={20} toggled={isOpen} toggle={onToggle} />
      )}
      <Anchor
        as={Link}
        href="/"
        margin="0"
        label={
          <Box height="48px" width="180px">
            <Image src={logo} fit="contain" width="100%" />
          </Box>
        }
      />
    </Box>
  );
}

function SidebarButton({
  Icon,
  label,
  href,
  isOpen,
  closeSidebar,
  location,
  ...rest
}: {
  [x: string]: any;
  Icon: any;
  label: any;
  href: any;
  isOpen: any;
  closeSidebar: any;
  location: any;
}): JSX.Element {
  const active = location?.pathname === href;
  return (
    <Button
      as={Link}
      gap="medium"
      active={active}
      alignSelf="start"
      plain
      icon={<Icon color={active ? 'brand' : 'dark-4'} />}
      label={<Text weight="bold">{label}</Text>}
      onClick={() => {
        if (isOpen) {
          closeSidebar();
        }
      }}
      to={href}
      href={href}
      {...rest}
    />
  );
}

function SidebarFooter({ isOpen, closeSidebar, location, isMobile }) {
  return (
    <div className="mb-20 flex gap-3 md:mb-10">
      {/* <SidebarButton Icon={<Chat />} label="Live Chat"  href="/isOpen={isOpen} /> */}
      <SidebarButton
        location={location}
        Icon={Logout}
        label="Logout"
        href="/auth/logout"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
      {/* <SidebarButton
        location={location}
        Icon={Help}
        label="Help"
        href="/help"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      /> */}
    </div>
  );
}

// TODO: in mobile side nav, show preview of what to expect in different oages within the menu
// animate it

interface MainNavigationProps {
  session: any;
  isOpen: boolean;
  closeSidebar: any;
  size: any;
  showLogo?: boolean;
}
function MainNavigation({
  session,
  isOpen,
  closeSidebar,
  size,
  showLogo,
  location
}: MainNavigationProps) {
  return (
    <Nav
      className="divide-y"
      gap="small"
      responsive={false}
      animation="slideRight"
      width={{ min: '255px' }}
      overflow={{ vertical: 'scroll' }}>
      <UserMenu session={session} />
      <SidebarButton
        location={location}
        Icon={StatusInfoSmall}
        label="Home"
        href="/"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
      <SidebarButton
        location={location}
        Icon={Contact}
        label="Customers"
        href="/customers"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
      {/* <SidebarButton
        location={location}
        Icon={Projects}
        label="Engage"
        href="/engage"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      /> */}
      <SidebarButton
        location={location}
        Icon={Analytics}
        label="Insight"
        href="/insight"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
      <SidebarButton
        location={location}
        Icon={Configure}
        label="Profile"
        href="/profile/#Profile"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
    </Nav>
  );
}

function TellerNavigation({
  session,
  isOpen,
  closeSidebar,
  size,
  showLogo,
  location
}: MainNavigationProps) {
  return (
    <Nav
      gap="small"
      responsive={false}
      animation="slideRight"
      width={{ min: '255px' }}
      overflow={{ vertical: 'scroll' }}>
      <UserMenu session={session} />
      <SidebarButton
        location={location}
        Icon={StatusInfoSmall}
        label="Home"
        href="/"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
      <SidebarButton
        location={location}
        Icon={Configure}
        label="Profile"
        href="/profile/#Profile"
        isOpen={isOpen}
        closeSidebar={closeSidebar}
      />
    </Nav>
  );
}
interface UserMenuProps {
  session: ISession;
}
function UserMenu({ session }: UserMenuProps) {
  let items = [{ label: 'login', href: '/auth/signin' }];
  if (session?.token) {
    if (session?.data.isOwner) {
      items = [
        { label: 'My business', href: '/business/edit' },
        { label: 'Members', href: '/business/members' }
        // { label: "Switch business", href: "/business/switch" }
      ];
    } else {
      items = [];
    }
  }
  return (
    <ul className="bg-base-100 rounded-box ml-4 w-full space-y-1 py-2 font-bold">
      <li>
        <div className="line-clamp-1 my-2 flex flex-row items-center gap-2">
          {session?.data?.org?.banner_media?.data?.url || session?.data?.org?.banner ? (
            <div className="w-8 rounded">
              <img
                className="rounded"
                src={session?.data?.org?.banner_media?.data?.url || session?.data?.org?.banner}
                alt={session?.data?.org?.name}
              />
            </div>
          ) : (
            <div className="avatar placeholder">
              <div className="bg-neutral-focus text-neutral-content w-8 items-center rounded">
                <span className="text-lg">
                  {session?.data?.org?.name
                    .split(' ')
                    .slice(2)
                    .map((n) => n[0])
                    .join('')}
                </span>
              </div>
            </div>
          )}
          <span className="break-all">
            {(session?.data?.org && session?.data?.org?.name) ||
              (session?.data?.id
                ? session.data.firstName || session.data.name || session.data.verified_phone
                : 'Account')}
          </span>
        </div>
      </li>
      {items.map(({ label, href }) => (
        <li key={label}>
          <a className="btn btn-ghost no-animation hover:bg-transparent" href={href}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
}
// function Media() {
//   return (
//     <Box direction="row" gap="xxsmall" justify="center">
//       <Anchor
//         as={Link}
//         a11yTitle="Share feedback on Github"
//         href="https://www.instagram.com/"
//         icon={<Instagram color="brand" />}
//       />
//       <Anchor
//         as={Link}
//         a11yTitle="Chat with us on Slack"
//         href="https://www.facebook.com/"
//         icon={<FacebookOption color="brand" />}
//       />
//       <Anchor
//         as={Link}
//         a11yTitle="Follow us on Twitter"
//         href="https://twitter.com/"
//         icon={<Twitter color="brand" />}
//       />
//     </Box>
//   );
// }

function ErrorFallback({ error, componentStack, resetError }) {
  return (
    <Box pad="xlarge" gap="medium" fill="vertical" justify="center" align="center">
      <Text>Oops! Something went wrong.</Text>
      <Text>We are looking into it.</Text>
      <Box width="small">
        <Button label="Go Home" href="/" primary />
      </Box>
    </Box>
  );
}

const DesktopSideBar = styled(Sidebar)`hidden md:block`;
const MobileLayer = styled(Layer)`
  md: hidden;
`;
const Body = tw(Box)`flex flex-col min-h-screen`;
const Content = styled(Box)(({ isAuth }) => [
  tw`scrollbar-hide`,
  tw`flex-grow`,
  !isAuth && tw`pt-5 pb-24`
]);
const StickyHeader = tw(Header)`sticky top-0 z-10`;

export default function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const {
    state: { session }
  } = useAuth();
  const size = React.useContext(ResponsiveContext);
  const [sidebar, setSidebar] = useState(false);
  const isAuth = location.pathname.includes('auth');

  const closeSidebar = () => {
    setSidebar(!sidebar);
  };

  useEffect(() => {
    if (location.pathname.includes('start')) {
      setSidebar(false);
    } else if (sidebar !== true && size !== 'small') {
      // setSidebar(true);
    }
  }, [location.pathname]);

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      <Box>
        <StickyHeader
          pad={{ horizontal: 'medium', vertical: 'small' }}
          direction="row-responsive"
          elevation="1"
          background="white"
          border={{ side: 'bottom', size: '0.5px', color: 'light-4' }}
          justify="between"
          gap="medium">
          <Box direction="row" align="center" gap="small" fill="horizontal" overflow="hidden">
            {size === 'small' && session?.data?.id && (
              <Hamburger
                hideOutline
                rounded
                size={20}
                toggled={sidebar}
                toggle={() => {
                  if (size === 'small') {
                    setSidebar(!sidebar);
                  }
                }}
              />
            )}
            <Navbar session={session} />
          </Box>
          {/* {size !== 'small' && (
            <Box justify="center" align="center">
              <UserMenu session={session} />
            </Box>
          )} */}
        </StickyHeader>
        <Body>
          {session?.data?.id && size === 'small' && sidebar && (
            <MobileLayer position="left" full="vertical">
              <Sidebar
                background="background"
                overflow={{ vertical: 'scroll' }}
                responsive={false}
                gap="small"
                header={
                  <Box>
                    <SidebarHeader
                      session={session}
                      isOpen={sidebar}
                      onToggle={closeSidebar}
                      withFooter
                    />
                  </Box>
                }
                pad={{ left: 'medium', right: 'large', vertical: 'small' }}>
                <Box
                  height={{ min: '95%' }}
                  justify="between"
                  gap="small"
                  pad={{ bottom: 'large' }}>
                  {session?.data.isOwner ? (
                    <MainNavigation
                      session={session}
                      size={size}
                      isOpen={sidebar}
                      closeSidebar={closeSidebar}
                    />
                  ) : (
                    <TellerNavigation
                      session={session}
                      size={size}
                      isOpen={sidebar}
                      closeSidebar={closeSidebar}
                    />
                  )}
                  <SidebarFooter
                    location={location}
                    isMobile
                    closeSidebar={closeSidebar}
                    isOpen={sidebar}
                  />
                </Box>
              </Sidebar>
            </MobileLayer>
          )}
          <Box direction="row" flex="grow" fill>
            <Collapsible direction="horizontal" open={typeof session?.data?.id !== 'undefined'}>
              {size !== 'small' && (
                <DesktopSideBar
                  background="#fff"
                  // margin="medium"
                  // round="small"
                  // border={{ side: 'right', size: '0.5px', color: 'light-4' }}
                  responsive={false}
                  pad={{ left: 'medium', right: 'large', vertical: 'small' }}>
                  <Box gap="large">
                    {session?.data.isOwner ? (
                      <MainNavigation
                        session={session}
                        size={size}
                        isOpen={sidebar}
                        closeSidebar={closeSidebar}
                      />
                    ) : (
                      <TellerNavigation
                        session={session}
                        size={size}
                        isOpen={sidebar}
                        closeSidebar={closeSidebar}
                      />
                    )}
                    <SidebarFooter closeSidebar={closeSidebar} isOpen={sidebar} />
                  </Box>
                </DesktopSideBar>
              )}
            </Collapsible>
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              {/* <View /> */}
              <Content
                isAuth={isAuth}
                fill="horizontal"
                overflow={{ horizontal: 'hidden', vertical: 'auto' }}>
                {children}
              </Content>
            </Sentry.ErrorBoundary>
          </Box>
        </Body>
        <footer className="footer border-base-300 border-t bg-transparent  p-10">
          <div>
            <LogoIcon full />
            <p>
              <br /> 2023 Vazapay Limited - RC 1901878
              <span className="badge badge-xs ml-2">
                📜 {gitInfo.commit.shortHash} {gitInfo.tags}
              </span>
            </p>
          </div>
        </footer>
      </Box>
    </>
  );
}
