import {
  Box, RangeInput as GRangeInput,
} from 'grommet';
import React, {
  ChangeEvent, ChangeEventHandler, EventHandler, useEffect,
} from 'react';
import tw from 'twin.macro';

export interface RangeInputProps {
  min?: number
  max?: number
  step?: number
  defaultValue?: number
  onChange: EventHandler<any>
}

const RangeContainer = tw.div`border-gray-200 rounded-lg my-4
border-solid border-[1px]`;
const RangeInputContainer = tw.div`flex my-4 px-4 items-center gap-8`;
const RangeInputInnerContainer = tw.div`flex-row w-full`;
const RangeLabelContainer = tw.div`w-full flex justify-between items-center`;
const RangeLimitsContainer = tw.div``;
const RangeLabel = tw.span`font-bold text-sm text-gray-500`;
const ValueContainer = tw.div`flex flex-col items-center pb-2`;
const ValueLabel = tw(RangeLabel)`text-lg text-black`;
const ValueSubLabel = tw(RangeLabel)`text-[10px] text-black`;
export function RangeInput({
  name, min, max, step, defaultValue, onChange,
}:RangeInputProps) {
  const [value, setValue] = React.useState<number>(defaultValue);
  const [isAddDisabled, setIsAddDisabled] = React.useState<boolean>();
  const [isSubtractDisabled, setIsSubtractDisabled] = React.useState<boolean>();

  const doOnChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    onChange({ target: { value } });
  }, [value]);

  return (
    <RangeContainer>
      <RangeLimitsContainer />
      <RangeInputContainer>
        {/* <Button
          plain={false}
          disabled={isSubtractDisabled}
          icon={<Subtract color="neutral-2" />}
          onClick={() => {
            if (value > 0) {
              setIsAddDisabled(false);
              setValue(value - 1);
            } else setIsSubtractDisabled(true);
          }}
        /> */}
        <RangeInputInnerContainer>
          <RangeLabelContainer>
            <RangeLabel>
              {min}
            </RangeLabel>
            <ValueContainer>
              <ValueLabel>{value}</ValueLabel>
              <ValueSubLabel>
                NGN
                {' '}
                {value}
              </ValueSubLabel>
            </ValueContainer>
            <RangeLabel>
              {max}
            </RangeLabel>
          </RangeLabelContainer>
          <GRangeInput
            type="number"
            value={value}
            name={name}
            min={min}
            max={max}
            step={step}
            color={[
              { value: (max - min) / 2 + min, color: 'brand', opacity: 0.5 },
              { value: max, color: 'red', opacity: 0.5 },
            ]}
            onChange={doOnChange}
          />
        </RangeInputInnerContainer>
        {/* <Button
          plain={false}
          disabled={isAddDisabled}
          icon={<Add color="neutral-2" />}
          onClick={() => {
            if (value < max) {
              setIsSubtractDisabled(false);
              setValue(value + 1);
            } else setIsAddDisabled(true);
          }}
        /> */}
      </RangeInputContainer>
    </RangeContainer>
  );
}
