import { Form, FormField, Box, Spinner, Select } from 'grommet';
import { NumberInput, validators } from 'grommet-controls';
import { FormNext, Search } from 'grommet-icons';
import React, { useState } from 'react';
import { IStorefront } from 'business/models/storefront';
import { useModal } from 'app/hooks/useModal';
import { PhoneInput } from '../PhoneInput';
import { Button } from '../Button';

export interface PhoneMoneyFormProps {
  submitText?: string;
  loading: boolean;
  pinRequired?: boolean;
  onSubmit: any;
  onChange?: any;
  mode: 'send' | 'receive';
  customer?;
  wallet?: IWallet;
}

export function PhoneMoneyForm({
  loading,
  onSubmit,
  submitText,
  pinRequired,
  onChange,
  wallet,
  mode
}: PhoneMoneyFormProps) {
  const [reasons, setReasons] = useState([
    { value: 'change', label: 'Giving out change' },
    { value: 'completion', label: 'Completing a purchase' },
    { value: 'payment', label: 'Paying for a product or service' }
  ]);
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('0');
  const [reason, setReason] = useState(reasons[0].value);

  const submit = () => {
    onSubmit({ reason, phone, amount: parseFloat(amount) * 100 });
  };

  const valueChange = (field, value) => {
    onChange({ phone, amount, [field]: value });
    switch (field) {
      case 'phone':
        setPhone(value);
        break;
      case 'amount':
        setAmount(value);
        break;
      case 'reason':
        setReason(value);
        break;
      default:
        break;
    }
  };
  return (
    <Form value={{ phone, amount, reason }} onSubmit={submit} validate="change">
      <FormField
        label="Customer Phone"
        name="phone"
        required
        value={phone}
        disabled={pinRequired || loading}
        help="eg +234 803 000 0000"
        width="100%">
        <div className="flex flex-row items-center justify-center">
          <PhoneInput
            value={phone}
            disabled={loading || pinRequired}
            name="phone"
            onChange={(value) => {
              valueChange('phone', value);
            }}
          />
          <button
            className="btn btn-ghost"
            disabled={loading || pinRequired}
            kind="toolbar"
            id="search-button"
            // onClick={() => setSearchFocused(true)}
          >
            <Search />
          </button>
        </div>
      </FormField>
      <FormField
        label="Amount"
        name="amount"
        type="number"
        required
        disabled={pinRequired || loading}
        value={amount}
        validate={(val) => {
          const currentAmount = parseFloat(val);
          if (currentAmount === 0) {
            return {
              message: 'Amount should be greater than 0',
              status: 'info'
            };
          }
          if (mode === 'send' && currentAmount > wallet.max_customer_credits) {
            return {
              message: `You can only send a maximum of ${wallet.max_customer_credits}`,
              status: 'info'
            };
          }
          return undefined;
        }}>
        <Box justify="center" width={{ min: 'small', max: 'large' }}>
          <NumberInput
            value={amount}
            disabled={pinRequired || loading}
            decimals={2}
            step={50}
            pattern={undefined}
            decimalSymbol="."
            placeholder="Enter amount of credits"
            name="amount"
            onChange={(e) => {
              valueChange('amount', e.target.value);
            }}
            validation={[validators.required()]}
          />
        </Box>
      </FormField>
      <FormField
        name="reason"
        type="string"
        label="What's this for?"
        margin={{ bottom: 'small' }}
        disabled={loading}
        required>
        <Select
          value={reason}
          labelKey="label"
          placeholder="Choose a reason for this transaction"
          disabled={loading}
          valueKey={{ key: 'value', reduce: true }}
          options={reasons}
          onChange={(e) => {
            valueChange('reason', e.target.value);
          }}
        />
      </FormField>
      <button disabled={loading} className="btn btn-primary" type="submit">
        {loading ? (
          <Spinner color="white" className="mr-3" />
        ) : (
          <FormNext className="mr-3 fill-white" />
        )}
        {submitText || 'Continue'}
      </button>
    </Form>
  );
}

PhoneMoneyForm.defaultProps = {
  submitText: 'submit',
  pinRequired: false,
  customer: null,
  onChange: () => {},
  storefronts: []
};
