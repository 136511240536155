import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Header, Heading, ResponsiveContext, Text, Grid, Card, CardBody } from 'grommet';
import { DocumentMissing } from 'grommet-icons';
import format from 'date-fns/format';
import { MiniWalletTile } from 'app/design/WalletTile/WalletTile.web';
import { HorizontalList } from '../HorizontalList';

export interface CustomerListProps {
  containerRef?;
}

const allData = [
  { name: 'Apex-Server', status: 'Ready' },
  { name: 'AuroraEdge-7.7.-edgedeploy4', status: 'Paused' },
  { name: 'ECAAS3', status: 'Ready' },
  { name: 'ECP-Master-01', status: 'Ready' },
  { name: 'ECaas-EdgeSite1', status: 'Paused' },
  { name: 'IronMan-2', status: 'Ready' },
  { name: 'california_1', status: 'Paused' }
];

const defaultFilters = {};

export function CustomerList({ records }: CustomerListProps) {
  // containerRef above is for demo purposes only, remove in production
  const [data, setData] = useState(records);
  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchFocused, setSearchFocused] = useState(false);
  const [search, setSearch] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    if (searchFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchFocused, setSearchFocused]);

  useEffect(() => {
    if (filters !== defaultFilters) {
      setFiltering(true);
    } else {
      setFiltering(false);
    }
  }, [filters, setFiltering]);

  return (
    <Box gap="medium" width={{ max: 'xxlarge' }} pad={{ horizontal: 'medium' }} margin="auto" fill>
      <Header pad={{ top: 'medium' }}>
        <Box gap="xsmall">
          <Heading level={2} margin={{ bottom: 'small', top: 'none' }}>
            Customers
          </Heading>

          {/* <RecordSummary data={data} filtering={filtering} /> */}
        </Box>
      </Header>
      {records.length === 0 && (
        <Box
          pad="xlarge"
          justify="center"
          align="center"
          gap="small"
          margin={{ horizontal: 'medium', vertical: 'small' }}
          background="light-1"
          round="small">
          <DocumentMissing /> <Text>You do not have any customers</Text>
        </Box>
      )}
      <Results data={records} />
    </Box>
  );
}

CustomerList.propTypes = {
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function RecordSummary({ total, data, filtering }) {
  return (
    <Box direction="row" gap="xxsmall">
      <Text size="small" weight="bold">
        {data.length}
      </Text>
      <Text size="small">{filtering ? `result${data.length > 1 ? 's' : ''} of ` : 'items'}</Text>
      {filtering && (
        <Box direction="row" gap="xxsmall">
          <Text size="small" weight="bold">
            {total}
          </Text>
          <Text size="small">{`item${allData.length > 1 ? 's' : ''}`}</Text>
        </Box>
      )}
    </Box>
  );
}

RecordSummary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      availability: PropTypes.number,
      name: PropTypes.string,
      location: PropTypes.string
    })
  ).isRequired,
  filtering: PropTypes.bool
};

function Results({ data }: { data: common.Customer[] }) {
  const size = useContext(ResponsiveContext);
  return (
    <Box overflow="auto" fill>
      <Grid
        columns={!['xsmall', 'small'].includes(size) ? 'medium' : { count: 1, size: 'auto' }}
        gap={!['xsmall', 'small'].includes(size) ? 'medium' : 'small'}>
        {data.map(({ customer, id, ...datum }) => (
          <Card key={id} background="background" margin="xxsmall" pad="0" onClick={() => {}}>
            <CardBody gap="xsmall" justify="between" pad="0">
              <Box align="start" margin={{ left: 'medium' }} className="px-4 py-6">
                <Text color="text-strong" size="large" weight="bold">
                  {!customer?.verified_phone ? customer?.Email : customer?.verified_phone}
                </Text>
                <Text color="text-light" size="small">
                  joined {customer && format(new Date(customer?.created_at), 'dd MMM yyyy')}
                </Text>
              </Box>
              {datum.balances && (
                <Heading
                  level={4}
                  margin={{ top: 'small', bottom: '0', left: 'medium' }}
                  className="px-4">
                  Balance
                </Heading>
              )}
              <HorizontalList>
                {datum.balances?.map((balance) => (
                  <MiniWalletTile key={balance.id} wallet={balance} title={balance.wallet?.title} />
                ))}
              </HorizontalList>
            </CardBody>
          </Card>
        ))}
      </Grid>
    </Box>
  );
}
