import React from 'react';
import PhoneInputWithCountry, { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountryRHF from 'react-phone-number-input/react-hook-form';
import styled from 'styled-components';
import tw from 'twin.macro';
import { define } from 'superstruct';

const Wrapper = styled.div`
  ${tw`w-full`}
  & .PhoneInput {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: currentColor;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
    ${tw`flex flex-row w-full`}
  }

  & .PhoneInputInput {
    ${tw`input input-bordered w-full rounded-md font-normal`}
  }

  & .PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
  }

  & .PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
  }

  & .PhoneInputCountryIcon--border {
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
  }

  & .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  & .PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
  }

  & .PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
  }

  & .PhoneInputCountry {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
  }

  & .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  & .PhoneInputCountrySelect[disabled],
  & .PhoneInputCountrySelect[readonly] {
    cursor: default;
  }

  & .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
  }

  & .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }

  & .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus);
  }

  & .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }
`;

export function PhoneInput({ control, ...props }: PhoneInputProps) {
  if (control)
    return (
      <Wrapper>
        <PhoneInputWithCountryRHF control={control} international defaultCountry="NG" {...props} />
      </Wrapper>
    );
  return (
    <Wrapper>
      <PhoneInputWithCountry international defaultCountry="NG" {...props} />
    </Wrapper>
  );
}

export const PhoneValidator = define('phone', (value) => {
  if (!value) return { message: 'required' };
  if (!isValidPhoneNumber(value as string)) {
    return { message: 'Please enter a valid phone number' };
  }
  return true;
});

export interface PhoneInputProps extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean;
}
PhoneInput.defaultProps = {
  disabled: false
};
