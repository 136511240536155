import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Grid,
  Box,
  Heading,
  Paragraph,
  Text,
  Spinner,
  Anchor,
  PageHeader,
  FormField,
  Collapsible
} from 'grommet';
import { useQueryParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby';
import PinField from 'react-pin-field';
import toast from 'react-hot-toast';
import useAuthenticated from 'hooks/useAuthenticated';
import api from 'services/api';
import useEnsureWallet from 'hooks/useEnsureWallet';
import Toast from 'components/Toast';
import { PhoneMoneyForm } from 'app/design/PhoneMoneyForm';
import '../app.css';
import { submitTransactionPin } from 'services/transaction';
import { getCustomerByPhone } from 'services/customers';
import { User } from 'business/models/user';
import { organizationWallet } from 'services/wallet';
import { useAuth } from 'context/auth';
import { useOrganization } from 'context/organization';
import { useModal } from 'app/hooks/useModal';
import { SuccessModal } from 'app/design/SuccessModal';
import { useQuery } from '@tanstack/react-query';
import { storeFronts } from 'services/storefront';
import { Button } from 'app/design/Button';
import { IWallet } from 'business/models/wallet';
import { FormClose } from 'grommet-icons';

function useFetchWallet(id: string, organization) {
  return useQuery({
    queryKey: ['wallet', id, organization],
    queryFn: async () => {
      const data = await organizationWallet(id, organization);
      return data;
    }
  });
}

function useWallets() {
  return useQuery({
    queryKey: ['storefronts'],
    queryFn: async () => {
      const { data } = await storeFronts('');
      return data;
    }
  });
}

function StoreFrontList({ onSelect }) {
  const { status, data, error, isFetching } = useWallets();
  if (isFetching)
    return (
      <div className="flex items-center justify-center p-10">
        <Spinner />
      </div>
    );
  const onSelected = (wallet) => () => onSelect(wallet);
  return (
    <div className="overflow-y-scroll pb-10">
      <ul className="list">
        {data.map((v) => (
          <>
            <li key={v.id} className="text-md border-b border-gray-200 p-4 px-6 font-bold">
              <span>{v.name}</span>
            </li>
            {v.wallets.map((wallet) => (
              <li key={wallet.id} className="border-b border-gray-200 p-4 px-6">
                <Button className="btn-ghost" onClick={onSelected(wallet)}>
                  {wallet.title}
                </Button>
              </li>
            ))}
          </>
        ))}
      </ul>
    </div>
  );
}

export default function Disperse() {
  useAuthenticated();
  useEnsureWallet();
  const [transaction, setTransaction] = useState<common.Transaction>({} as common.Transaction);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<IWallet>(null);
  const [message, setMessage] = useState(null);
  const [pin, setPin] = useState('');
  const pinRef = useRef(null);
  const [selectedSender, setSelectedSender] = useState(null);
  const { state } = useAuth();
  const { organization } = useOrganization();
  const user = Object.assign(new User(), state.session?.data);

  const { show, hide, RenderModal } = useModal(
    {
      title: '',
      onClose: () => {
        // setfocusedData(null);
      }
    },
    'modal'
  );
  const [walletID] = useQueryParam('id', StringParam);

  async function sendPin() {
    setLoading(true);
    // pinRef?.current?.focus();
    try {
      toast.loading('verifying pin...');
      await submitTransactionPin(transaction.id, pin);
      // if ((resp as common.Transaction).paid) {
      //   navigate('/');
      // }
      toast.dismiss();
      show();
      setTransaction(null);
    } catch (error) {
      toast.error(error.message);
    }
    setPin('');
    pinRef.current.forEach((input) => (input.value = ''));
    pinRef.current[0].focus();
    // setNotification(true);
    setLoading(false);
  }

  useEffect(() => {
    if (pin.length === 4) {
      sendPin();
    }
  }, [pin]);

  const { data: wallet, isLoading: fetching } = useFetchWallet(
    walletID,
    user.isOwner ? null : organization.id
  );

  const submit = async (data) => {
    setMessage(null);
    setTransaction({});
    setLoading(true);
    const resp = await api.post<any, common.Transaction>('/store/transactionrequest', {
      ...data,
      mode: 'pay',
      wallet: wallet.id,
      destinationWallet: selectedWallet?.id
    });
    if (resp.status === 200) {
      if (!resp.data?.pinRequired) {
        setNotification(true);
      }
      setTransaction(resp.data);
    } else {
      setNotification(true);
      setMessage(resp.data.msg);
    }
    setLoading(false);
  };

  const onChangeForm = (v) => {
    if (v.phone.length > 0) getCustomerByPhone(v.phone);
  };

  const {
    show: showStoreSearch,
    hide: hideStoreSearch,
    RenderModal: RenderStoreModal
  } = useModal(
    {
      title: 'Send to wallet',
      onClose: () => {}
    },
    'modal'
  );

  const onSelectWallet = (wallet) => {
    setSelectedWallet(wallet);
    hideStoreSearch();
  };

  return (
    <>
      {wallet?.wallet_class === 'global' && (
        <RenderStoreModal>
          <StoreFrontList onSelect={onSelectWallet} />
        </RenderStoreModal>
      )}
      <div className="w-full px-7 md:px-16">
        <Box align="stretch" justify="center" direction="column">
          <Box>
            <Anchor onClick={() => navigate('/')}>Back</Anchor>
          </Box>
          <Box height={{ min: '90px' }}>
            <PageHeader
              subtitle={loading || fetching ? <Spinner /> : `${wallet?.title} ● Wallet`}
              title="Send credit"
            />
          </Box>
          <div className="w-full md:w-4/12">
            {wallet?.wallet_class === 'global' && (
              <div>
                <span className="badge badge-lg">To</span>
                <div className="my-5 flex flex-row items-center gap-4">
                  {selectedWallet ? (
                    <span className="badge badge-lg badge-info">{selectedWallet.title}</span>
                  ) : (
                    <Button type="button" onClick={showStoreSearch}>
                      Choose a wallet
                    </Button>
                  )}
                  {selectedWallet && (
                    <Button plain onClick={() => setSelectedWallet(null)}>
                      <FormClose size="medium" />
                    </Button>
                  )}
                </div>
              </div>
            )}
            <PhoneMoneyForm
              onSubmit={submit}
              loading={loading || fetching || transaction?.pinRequired}
              submitText="Send"
              mode="send"
              wallet={wallet}
              onChange={onChangeForm}
            />
          </div>
          <Collapsible open={transaction?.pinRequired}>
            <Box width="medium" pad="small">
              <Heading level="4">Waiting for customer to verify</Heading>
              <FormField
                name="pin"
                value={pin}
                disabled={loading}
                label="Transaction PIN"
                help="Ask customer for their pin if this takes too long">
                <Box direction="row">
                  <PinField
                    className="pin-field"
                    ref={pinRef}
                    validate="0123456789"
                    length={4}
                    inputMode="numeric"
                    onChange={setPin}
                  />
                </Box>
              </FormField>
            </Box>
          </Collapsible>
        </Box>
      </div>
      <Toast
        status={transaction?.paired && transaction?.paid ? 'normal' : 'warning'}
        visible={notification}
        onClose={() => setNotification(false)}
        title={transaction?.paired && transaction?.paid ? 'Change Sent!' : 'Failed'}
        message={message || transaction?.sender_message}
      />
      <RenderModal>
        <SuccessModal
          onClose={hide}
          title="Success"
          message={
            transaction?.id &&
            `${
              transaction.initiator.profile?.firstName ||
              transaction.initiator?.firstName ||
              transaction.initiator?.verified_phone
            } has received ${transaction.amount / 100} credits in their ${wallet?.title} wallet`
          }
        />
      </RenderModal>
    </>
  );
}
